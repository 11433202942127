.form_container {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-self: center;
}

.heading {
  font-weight: 900;
}

.form_container > input,
.textarea {
  background-color: var(--bg);
  border: 1px solid var(--primary);
  border-radius: 0.1rem;
  font-size: 1.2rem;
  padding: var(--mb-1);
  margin: var(--mb-1) 0;
  color: var(--text);
}

.textarea {
  resize: vertical;
  font-family: var(--normalFont);
  height: 150px;
}

.sidenote {
  font-size: 12px;
}
