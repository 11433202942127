.project_card {
  color: var(--text) !important;
}

.project_card > h4 {
  margin: var(--mb-1) 0px;
}

.project_card > p {
  margin: var(--mb-1) 0px;
  font-size: 14px;
}

.previewImg {
  height: 150px;
  width: 100%;
  border-radius: 5px;
}

.tech_badges {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: var(--mb-2) 0px;
  color: var(--white);
}

.project_link {
  font-size: 1.5rem;
  margin-right: var(--mb-2);
  cursor: pointer;
  transition: 0.2s all;
}

.project_link:hover {
  transform: scale(1.1);
  color: var(--secondary);
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
