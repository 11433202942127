.navbarWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: var(--mb-2) 10px;
  z-index: 999;
  overflow-y: auto;
}

.iconsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconsContainer > p,
.brandContainer > p,
.themeIconContainer > p {
  font-size: 1rem;
  color: var(--text);
  transition: 0.1s ease-in;
  cursor: pointer;
}

.iconsContainer > p:hover,
.brandContainer > p:hover,
.themeIconContainer > p:hover {
  transform: scale(1.2);
  color: var(--primary);
}

.iconsContainer * {
  margin: var(--mb-1) 0px;
}

.brandContainer > p {
  /* background-color: black !important; */
  /* background-image: linear-gradient(45deg, #f3ec78, #af4261); */
  background-image: linear-gradient(135deg, var(--primary), var(--secondary));
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-family: var(--stylishFont);
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  -moz-user-select: none !important;
  -khtml-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .iconsContainer {
    display: none;
  }
  .navbarWrapper {
    flex-direction: row;
    width: 100%;
    height: max-content;
    position: fixed;
  }

  .themeIconContainer {
    position: absolute;
    right: 12px;
  }
}
