.wrapper {
  flex-direction: column;
  align-items: center;
}

.content_wrapper {
  display: flex;
  justify-content: space-evenly;
}

.image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
}

.resume_image {
  width: 200px;
  /* height: 100%; */
  border-radius: 5px;
}

.text_container {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text_container > p {
  padding: var(--mb-1);
}
.button_container {
  margin-top: var(--mb-2);
  text-align: center;
}

/* OR Divider */

.or_divider {
  font-size: 14px;
  color: var(--backgroundText);
  overflow: hidden;
  text-align: center;
  margin: var(--mb-3) 0;
}

.or_divider:before,
.or_divider:after {
  background-color: var(--backgroundText);
  content: "";
  display: inline-block;
  height: 0.01rem;
  position: relative;
  vertical-align: middle;
  width: 10%;
}

.or_divider:before {
  right: 0.5em;
  margin-left: -50%;
}

.or_divider:after {
  left: 0.5em;
  margin-right: -50%;
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .content_wrapper {
    flex-direction: column;
  }

  .resume_image {
    width: 200px;
  }

  .text_container {
    margin-top: var(--mb-2);
  }
}
