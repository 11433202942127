.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #121212; */
  min-height: 100vh;
}

.navLinkActive {
  color: var(--primary);
}

.preventUserSelect {
  -moz-user-select: none !important;
  -khtml-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
