.wrapper {
  flex-direction: column;
  align-items: center;
}

.content_wrapper {
  display: grid;
  column-gap: var(--mb-5);
  grid-template-columns: repeat(100, minmax(275px, 320px));
  width: 95%;
  overflow-x: auto;
}

.content_wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--backgroundText);
}

.content_wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: var(--backgroundText);
  border-radius: 10px;
}

.content_wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}

/* Horizontal View */
.horizontal_content {
  grid-template-columns: repeat(100, minmax(275px, 320px));
  width: 95%;
  overflow-x: auto;
}

/* Grid View */
.grid_content {
  grid-template-columns: repeat(auto-fit, minmax(275px, 320px));
  row-gap: var(--mb-5);
  justify-content: center;
  overflow-x: hidden;
  width: 90%;
}

/* Project View Toggler */

.view_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--mb-3);
}

.view_container > span {
  margin: 0 var(--mb-1);
}

.view_icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.2s all;
}

.view_icon:hover {
  transform: scale(1.3);
  color: var(--secondary);
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .content_wrapper {
    column-gap: var(--mb-3);
  }
}
