.landing_text {
  /* background-image: linear-gradient(45deg, #f3ec78, #af4261); */
  background-image: linear-gradient(135deg, var(--primary), var(--secondary));
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-family: var(--stylishFont);
  font-size: calc(var(--normalFont) * 6) !important;
  text-align: center;
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .landing_text {
    font-size: calc(var(--normalFont) * 4) !important;
  }
}
