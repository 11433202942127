.skill_card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--backgroundText);
  border-radius: var(--mb-1);
  padding: calc(var(--mb-1) * 1.5);
}

.logo_box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: var(--mb-3);
}

.logo {
  height: 40px;
  width: 40px;
  opacity: 1;
  position: absolute;
}

.content_box {
}

.content_box > h5 {
}

.content_box > p {
  font-size: 12px;
  margin-top: calc(var(--mb-1) * 0.75);
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
