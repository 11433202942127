.footer_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 30vh;
}

.icons_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.social_icon {
  font-size: var(--normal-font-size);
  transition: 0.1s ease-in;
  cursor: pointer;
  margin: 1rem 0.7rem;
  height: 30px !important;
  width: 30px !important;
}

.social_icon:hover {
  transform: scale(1.4, 1.4);
}

.text_container {
  text-align: center;
}

.text_container > p {
  margin: var(--mb-2) 0;
}

/*
.social_icons_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .social_icon {
    font-size: var(--normal-font-size);
    transition: 0.1s ease-in;
    cursor: pointer;
    margin: 1rem 0.7rem;
    height: 30px !important;
    width: 30px !important;
  }
  
  .social_icon:hover {
    transform: scale(1.4, 1.4);
  }
*/
