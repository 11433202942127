.button {
  width: min-content;
  padding: var(--mb-1) var(--mb-2);
  background-color: transparent;
  border: 2px solid var(--secondary);
  color: var(--secondary);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  border-radius: var(--mb-1);
}

.button:hover {
  box-shadow: 0 0 40px 40px var(--secondary) inset;
  color: var(--white);
}

.button:focus {
  outline: 0;
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .button {
    box-shadow: 0 0 40px 40px var(--secondary) inset;
    color: var(--white);
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .button {
    box-shadow: 0 0 40px 40px var(--secondary) inset;
    color: var(--white);
  }
}
