.wrapper {
  flex-direction: column;
  align-items: center;
}

.content_wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 300px));
  justify-content: center;
  column-gap: var(--mb-3);
  row-gap: var(--mb-3);
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
