.header {
  margin-bottom: var(--mb-3);
}

.header > p {
  font-size: 1.3rem;
  padding: var(--mb-1);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.sectionIcon {
  color: var(--primary);
  padding-bottom: 2px;
  margin: 0;
  vertical-align: sub;
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .header {
    text-align: center;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
