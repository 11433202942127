.wrapper {
  justify-content: space-evenly;
  align-items: flex-start;
}

/* Common for both Experience and Education */
.half_wrapper {
  flex-direction: column;
  width: 45%;
}

.content_wrapper {
  border-left: 3px solid var(--backgroundText);
  padding-left: 30px;
}

.card {
  padding-left: 10px;
}

.card::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  border-radius: 50%;
  border: 2px solid var(--primary);
  position: relative;
  top: 20px;
  left: -45px;
}

.logo {
  width: 40px;
  height: 40px;
  vertical-align: center !important;
}

.logo_org_box {
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.card > p {
  margin: var(--mb-1);
}

.organization {
  margin: 0;
  margin-left: 5px;
}

.secondary_info {
  font-size: 13px !important;
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }

  .half_wrapper {
    width: 90%;
  }

  .education_wrapper {
    margin-top: var(--mb-4);
  }
}
